import { FC, useEffect, useState } from "react";
import { IGoodsField } from "./IGoodsField";
import { Box, Stack, Typography } from "@mui/material";
import { GoodItem } from "./ui/GoodItem/GoodItem";
import { GoodsModalList } from "./ui/GoodsModalList/GoodsModalList";
import { IGoodItem } from "./ui/GoodItem/IGoodItem";
import goodsStore from "../../../../../store/goods.store";
import { IInsertGoodsRequest } from "../../../../../services/integration/IntegrationsRequest";
import IntegrationsService from "../../../../../services/integration/integrations.service";
import integrationTableStore from "../../../../../store/integrationTable.store";
import { AxiosError } from "axios";
import { Add } from "@mui/icons-material";
import { CustomIconButton } from "../../../../UI/Buttons/CustomIconButton/CustomIconButton";
import { EditIconButton } from "../../../../UI/Buttons/EditIconButton/EditIconButton";

export const GoodsField: FC<IGoodsField> = (props) => {
  const { goods, id, readOnly, forFirstIntegrationWithChannel } = props;

  const [totalGoods, setTotalGoods] = useState<IGoodItem[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const allNameGoods = [...goods].map((item) => item.name);

  const changeGoods = (article: string, value: boolean) => {
    setTotalGoods((prev) =>
      prev.map((item) => (item.article === article ? { ...item, isActive: value } : item))
    );
  };

  const closeModal = () => {
    setTotalGoods((prev) => {
      return [...goodsStore?.goods!].map((item) => {
        if (allNameGoods.find((name) => item.name === name)) {
          return item;
        } else return { ...item, isActive: false };
      });
    });
    setOpen(false);
  };

  const submit = () => {
    setLoading(true);

    const option: IInsertGoodsRequest = {
      id,
      items: totalGoods.filter((item) => item.isActive),
    };
    IntegrationsService.insertGoods(option)
      .then((res: any) => {
        // console.log(res, 'res goods update field')
        integrationTableStore.insertGoods(
          id,
          totalGoods.filter((item) => item.isActive),
          res.data?.goodsCategoryCode,
          res.data?.update_id,
          res.data.goodsCategory
        );
        setOpen(false);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err goods update')
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setTotalGoods((prev) => {
      return [...goodsStore?.goods!].map((item) => {
        if (allNameGoods.find((name) => item.name === name)) {
          return item;
        } else return { ...item, isActive: false };
      });
    });
  }, [goods]);

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={goods.length > 0 ? "space-between" : "center"}>
        <Stack
          direction={"column"}
          justifyContent={"center"}
          spacing={"4px"}
          onClick={readOnly ? undefined : () => setOpen(true)}
          sx={{ cursor: readOnly ? "auto" : "pointer" }}>
          {goods.map((item) => (
            <GoodItem key={item.name} {...item} maxWidth="130px" />
          ))}
          {goods.length === 0 && !readOnly && (
            <Box>
              <CustomIconButton>
                <Add />
              </CustomIconButton>
            </Box>
          )}
          {readOnly && goods.length === 0 && <Typography>—</Typography>}
        </Stack>
        {!readOnly && goods.length > 0 && (
          <Stack direction={"row"}>
            <EditIconButton size="small" onClick={() => setOpen(true)} disabled={isLoading} />
          </Stack>
        )}
      </Stack>
      {isOpen && (
        <GoodsModalList
          id={id}
          goods={totalGoods}
          open={isOpen}
          onClose={closeModal}
          changeGoods={changeGoods}
          onSubmit={submit}
          loading={isLoading}
          forFirstIntegrationWithChannel={forFirstIntegrationWithChannel}
        />
      )}
    </>
  );
};
