import { Stack, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FC, useState } from "react";
import { Dayjs } from "dayjs";
import { ICreateDocumentDatePicker } from "./ICreateDocumentDatePicker";
import { getDatePickerIconsStyle } from "../../../../../../components/UI/DateCustomInput/shared/getDatePickerIconsStyle";

const CreateDocumentDatePicker: FC<ICreateDocumentDatePicker> = (props) => {
  const { value, onChange, placeholder, required, label, validate, title, name, ...rest } = props;

  const [error, setError] = useState<null | string>(null);

  const validateRequired = (value: Dayjs | null) => {
    if (required && !value) {
      return "Заполните поле";
    }

    return null;
  };

  const handleChange = (newValue: Dayjs | null) => {
    if (newValue && newValue.isValid()) {
      let validationError = validateRequired(newValue);

      if (!validationError && validate) {
        validationError = validate(newValue);
      }

      setError(validationError);

      if (!validationError) {
        onChange(newValue, name);
      }
    } else {
      setError("Некорректные данные");
    }
  };

  return (
    <Stack sx={{ position: "relative", flexDirection: "row", alignItems: "center" }}>
      {title && (
        <Typography minWidth={"200px"}>
          {title}
          {": "}
          {required ? (
            <Typography component={"span"} color={"error"}>
              *
            </Typography>
          ) : (
            ""
          )}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ru"}>
        <DatePicker
          name={name}
          value={value}
          onChange={handleChange}
          sx={{ maxWidth: "250px" }}
          slotProps={{
            textField: {
              variant: "outlined",
              placeholder: placeholder,
              size: "small",

              required: required,
              error: !!error,
              fullWidth: true,
              sx: {
                maxWidth: "250px",
                position: "relative",
                "& .MuiFormHelperText-root": {
                  margin: "0",
                },
              },
              helperText: error ? (
                <Typography
                  variant="caption"
                  color="error"
                  component={"span"}
                  sx={{
                    position: "absolute",
                    bottom: "-14px",
                    left: "14px",
                    margin: "0",
                  }}>
                  {error}
                </Typography>
              ) : (
                ""
              ),
            },
            ...getDatePickerIconsStyle(),
            ...rest,
          }}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default CreateDocumentDatePicker;
