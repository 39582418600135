import { IAgreementsFilterQuery } from "../../../../../../../services/agreements/IAgreementsRequest";
import { IFromToArray } from "../../../../../../../services/integration/IntegrationsRequest";
import userStore from "../../../../../../../store/user.store";

export const getFilterAgreementsQuery = (searchQuery: URLSearchParams): IAgreementsFilterQuery => {
  const transormValueToArrayFromTo = (
    searchArr: string[],
    isDate?: boolean
  ): IFromToArray<number | string> => {
    const array = !isDate ? searchArr.map((x) => +x) : searchArr;

    const fromToArray: IFromToArray<number | string> = {
      from: array[0],
      to: array[1],
    };
    return fromToArray;
  };

  const option: IAgreementsFilterQuery | any = {
    status: searchQuery.get("status") || null,
    userId: searchQuery.get("all_agreements") ? userStore?.user?.id : null,
    integrationId: searchQuery.get("integrationId")
      ? JSON.parse(searchQuery.get("integrationId")!)?.id
      : null,
    createdAt: searchQuery.get("createdAt")
      ? transormValueToArrayFromTo(searchQuery.get("createdAt")?.split(",")!, true)
      : null,
    allDecisionDate: searchQuery.get("allDecisionDate")
      ? transormValueToArrayFromTo(searchQuery.get("allDecisionDate")?.split(",")!, true)
      : null,
    channelType: searchQuery.get("channelType")?.split(",") || null,
    isVerifiedByGov: searchQuery.get("isVerifiedByGov")
      ? searchQuery.get("isVerifiedByGov") === "true"
      : null,
  };

  const filterEmptyFieldOption: any = {};

  for (let key in option) {
    if (option[key] || option[key] === false) {
      filterEmptyFieldOption[key] = option[key];
    }
  }

  return filterEmptyFieldOption;
};
