import { AxiosError } from "axios";
import { FC, useState } from "react";
import { IUpdateStatusesRequest } from "../../../../../services/integration/IntegrationsRequest";
import { StatusesList } from "../../../../Statuses/StatusesList";
import { IStatusField } from "./IStatusField";

export const StatusField: FC<IStatusField> = (props) => {
  const {
    id,
    type,
    update_id,
    statusStore,
    onChangeStatus,
    onUpdateStatus,
    readOnly,
    integrationId,
    allAllowedMoves,
    onChangeType,
    onChange,
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isShowAlert, setShowAlert] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState<null | string>(null);

  const changeStatus = (value: string) => {
    setLoading(true);
    setError(null);

    const option: IUpdateStatusesRequest = {
      id,
      field: "status",
      new_value: value,
      update_id,
    };

    onUpdateStatus(option)
      .then((res) => {
        handleClose();
        onChangeStatus(id, res.data.update_id, value);
        onChange && onChange(res?.data);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err change stuts')
          setError(err?.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeTypeAgreement = (type: string, update_id: number) => {
    if (onChangeType) {
      onChangeType(id, update_id, type);
    }
    handleClose();
  };

  return (
    <StatusesList
      type={type}
      anchorEl={anchorEl}
      onOpenList={handleClick}
      onCloseList={handleClose}
      changeStatus={changeStatus}
      statusStore={statusStore}
      loading={isLoading}
      showAlertSuccess={isShowAlert}
      closeAlertSuccess={() => setShowAlert(false)}
      isShowAlerError={isError}
      closeAlertError={() => setError(null)}
      readOnly={readOnly}
      integrationId={integrationId}
      allAllowedMoves={allAllowedMoves}
      onChangeType={onChangeType ? changeTypeAgreement : undefined}
    />
  );
};
