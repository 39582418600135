import { CreditScore, VideoCameraFrontOutlined } from "@mui/icons-material";
import { Avatar, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { VerifiedIcon } from "../../../../../pages/ChannelCardPage/ui/ChannelAsideMain/ui/VerifiedByGov/VerifiedIcon/VerifiedIcon";
import channelStore from "../../../../../store/channel.store";
import { ISocialType } from "./ISocialType";

export const SocialType: FC<ISocialType> = observer((props) => {
  const {
    code,
    text,
    sxProps,
    showVideoReviewIcon,
    showPaymentOnCardIcon,
    showVerifiedIcon,
    audience,
  } = props;

  const currentIcon = channelStore?.channels![code];

  return (
    <Stack direction={"row"} spacing={0.5} alignItems={"center"} sx={sxProps}>
      {currentIcon && <Avatar src={currentIcon?.icon} alt={code} sx={{ height: 20, width: 20 }} />}
      {showVideoReviewIcon && <VideoCameraFrontOutlined />}
      {showVerifiedIcon && (
        <VerifiedIcon isVerified bgSize="medium" iconSize="small" audience={audience || 0} />
      )}
      {showPaymentOnCardIcon && <CreditScore color="primary" />}
      {text ? <Typography noWrap>{text}</Typography> : null}
    </Stack>
  );
});
