import { IRenderFilterLists } from "../../components/Filters/interfaces/IRenderFilterItem";
import {
  getIsVeifiedByGovStatuses,
  getPaymentFilterHotSlots,
  getPaymentFilterStatuses,
  getPaymentType,
  getTypeChanell,
} from "../../components/FinancecTable/shared/dataList";
import { IStrategyFiltersField } from "./interface/IStrategyFiltersField";
import { IFilterSelectItem } from "../../components/Filters/UI/FilterSelectChip/IFilterSelectChip";
import allTzStore from "../../store/allTz.store";
import {
  getAllGoodsCategories,
  getAllPaymentStatus,
  getAllTz,
  getChannelSizeFilterItems,
  getGoodsFilterItems,
  getManagerFilterItems,
  getSocialTypeFiltes,
} from "../../components/IntegrationTable/shared/dataList";
import statusesStore from "../../store/statuses.store";
import ChannelTheameStore from "../../store/channelTheame";
import { getChannelTheameFilters } from "../../components/ChannelTable/ui/ChannelFiter/shared/dataList";
import channelStore from "../../store/channel.store";
import selectsDataStore from "../../store/selectsData.store";
import goodsStore from "../../store/goods.store";
import categoriesGoodsStore from "../../store/categoriesGoods.store";
import AllUsersStore from "../../store/AllUsers.store";

export const textsFilterStrategy = (item: IRenderFilterLists): IStrategyFiltersField => {
  const inputTypeObj: { [key: string]: React.HTMLInputTypeAttribute } = {
    inn: "number",
  };

  const strategyItem: IStrategyFiltersField = {
    ...item,
    isTexts: true,
    inputType: inputTypeObj[item.code] ?? undefined,
  };
  return strategyItem;
};

export const chipsFilterStrategy = (item: IRenderFilterLists): IStrategyFiltersField => {
  const isWrittingChips = ["id", "integrationId"];
  const searchChipCode = ["channel", "channeMocklId", "channelId"];

  const inputTypeObj: { [key: string]: React.HTMLInputTypeAttribute } = {
    id: "number",
    integrationId: "number",
  };

  const referencesAndContentObj: {
    [key: string]: {
      referenceStore: any;
      referenceContentFn: (referenceStore: any) => IFilterSelectItem[];
      limit: number;
    };
  } = {
    status: {
      referenceStore: statusesStore.statuses,
      referenceContentFn: getPaymentFilterStatuses,
      limit: 3,
    },
    channelTheme: {
      referenceStore: ChannelTheameStore.theame,
      referenceContentFn: getChannelTheameFilters,
      limit: 3,
    },
    channelType: {
      referenceStore: channelStore.channels,
      referenceContentFn: getSocialTypeFiltes,
      limit: 3,
    },
    size: {
      referenceStore: selectsDataStore.channelSizes,
      referenceContentFn: getChannelSizeFilterItems,
      limit: 3,
    },
    goods: {
      referenceStore: goodsStore.goods,
      referenceContentFn: getGoodsFilterItems,
      limit: 3,
    },
    goodsCategory: {
      referenceStore: categoriesGoodsStore.categories,
      referenceContentFn: getAllGoodsCategories,
      limit: 3,
    },
    tz: {
      referenceStore: allTzStore.tzItems,
      referenceContentFn: getAllTz,
      limit: 2,
    },
    foundById: {
      referenceStore: AllUsersStore.users,
      referenceContentFn: getManagerFilterItems,
      limit: 3,
    },
    releaseUserId: {
      referenceStore: AllUsersStore.users,
      referenceContentFn: getManagerFilterItems,
      limit: 3,
    },
    paymentStatus: {
      referenceStore: statusesStore.payments,
      referenceContentFn: getAllPaymentStatus,
      limit: 3,
    },
    channelStatus: {
      referenceStore: statusesStore.channel,
      referenceContentFn: getPaymentFilterStatuses,
      limit: 3,
    },
    typeCode: {
      referenceStore: channelStore.channels,
      referenceContentFn: getSocialTypeFiltes,
      limit: 3,
    },
    theme: {
      referenceStore: ChannelTheameStore.theame,
      referenceContentFn: getChannelTheameFilters,
      limit: 3,
    },
    releaseById: {
      referenceStore: AllUsersStore.users,
      referenceContentFn: getManagerFilterItems,
      limit: 3,
    },
    paymentType: {
      referenceStore: selectsDataStore.paymentType,
      referenceContentFn: getPaymentType,
      limit: 3,
    },
    payStatus: {
      referenceStore: statusesStore.pay,
      referenceContentFn: getPaymentFilterStatuses,
      limit: 3,
    },
  };

  const showOptionLabelValue = ["tz"];
  const agency = ["agency"];
  const searchDirection = ["payDirection"];

  const strategyItem: IStrategyFiltersField = {
    ...item,
    inputType: inputTypeObj[item.code] ?? undefined,
    isReferenceChips: referencesAndContentObj[item.code] ? true : false,
    referenceStore: referencesAndContentObj[item.code]?.referenceStore || undefined,
    referenceStoreContentFn: referencesAndContentObj[item.code]?.referenceContentFn || undefined,
    isSearchChip: searchChipCode.includes(item.code),
    isWrittingChips: isWrittingChips.includes(item.code),
    limit: referencesAndContentObj[item.code]?.limit || undefined,
    referenceShowOptionLabelValue: showOptionLabelValue.includes(item.code),
    isAgensyChips: agency.includes(item.code),
    isSearchDirection: searchDirection.includes(item.code),
    isGoodsSelect: item.code === "goods",
  };
  return strategyItem;
};

export const selectsFilterStrategy = (item: IRenderFilterLists): IStrategyFiltersField => {
  const contentSelectObj: { [key: string]: () => IFilterSelectItem[] } = {
    firstIntegrationWithChannel: getTypeChanell,
    isVerifiedByGov: getIsVeifiedByGovStatuses,
    edo: getPaymentFilterHotSlots,
    hasAct: getPaymentFilterHotSlots,
    hasSelfEmployedCheck: getPaymentFilterHotSlots,
    isServiceProvided: getPaymentFilterHotSlots,
    isOriginalsReceived: getPaymentFilterHotSlots,
    ord_hasAct: getPaymentFilterHotSlots,
  };

  const selectField: { [key: string]: any } = {
    counteragentType: "type",
    operator: "operator",
  };

  const strategyItem: IStrategyFiltersField = {
    ...item,
    isFinanceSelects: true,
    contentFn: contentSelectObj[item.code],
    isSelectFilterField: selectField[item.code],
    isSelectFilterFieldType: selectField[item.code],
  };
  return strategyItem;
};

export const multiplesFilterStrategy = (item: IRenderFilterLists): IStrategyFiltersField => {
  const steps = ["cpv", "ctr", "cr", "crThrough"];

  const dataMinMaxObj: { [key: string]: number[] } = {
    targetAudience: [0, 100],
  };

  const strategyItem: IStrategyFiltersField = {
    ...item,
    isMultiples: true,
    step: steps.includes(item.code) ? 0.1 : undefined,
    dataMinMax: dataMinMaxObj[item.code],
  };
  return strategyItem;
};

export const checkboxsFilterStrategy = (item: IRenderFilterLists): IStrategyFiltersField => {
  const strategyItem: IStrategyFiltersField = {
    ...item,
    isCheckboxs: true,
  };
  return strategyItem;
};

export const radiosFilterStrategy = (item: IRenderFilterLists): IStrategyFiltersField => {
  const strategyItem: IStrategyFiltersField = {
    ...item,
    isRadios: true,
  };
  return strategyItem;
};

export const datesFilterStrategy = (item: IRenderFilterLists): IStrategyFiltersField => {
  const strategyItem: IStrategyFiltersField = {
    ...item,
    isDates: true,
  };
  return strategyItem;
};
