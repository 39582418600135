import { IRenderFilterLists } from "../../components/Filters/interfaces/IRenderFilterItem";
import {
  checkboxsFilterStrategy,
  chipsFilterStrategy,
  datesFilterStrategy,
  multiplesFilterStrategy,
  radiosFilterStrategy,
  selectsFilterStrategy,
  textsFilterStrategy,
} from "./filterStrategyRealisation";
import { IStrategyFiltersField } from "./interface/IStrategyFiltersField";

export type allFiltersCode =
  | "id"
  | "channel"
  | "channelId"
  | "channeMocklId"
  | "status"
  | "channelStatus"
  | "theme"
  | "channelTheme"
  | "channelType"
  | "size"
  | "promokod"
  | "goods"
  | "goodsCategory"
  | "tz"
  | "foundById"
  | "releaseById"
  | "releaseUserId"
  | "firstIntegrationWithChannel"
  | "integration_paymentStatus"
  | "roi"
  | "cpv"
  | "ctr"
  | "cr"
  | "crThrough"
  | "validOrders"
  | "canceledOrders"
  | "views"
  | "likes"
  | "comments"
  | "traffic"
  | "prediction_orders"
  | "prediction_views"
  | "prediction_traffic"
  | "price"
  | "paidSum"
  | "integration_date"
  | "plannedPaidAt"
  | "paidAt"
  | "hasGuarantee"
  | "guaranteeNotCompleted"
  | "isVideoReview"
  | "initialPrice"
  | "agency"
  | "targetAudience"
  | "advertiser"
  | "searchSystem"
  | "typeCode"
  | "channelSize"
  | "dateFound"
  | "dateGiven"
  | "audience"
  | "isBanned"
  | "statisticDate"
  | "counteragentId"
  | "payStatus"
  | "edo"
  | "inn"
  | "counteragent"
  | "operator"
  | "counteragentType"
  | "amount"
  | "commission"
  | "paidSumm"
  | "totalSumm"
  | "hotSlot"
  | "paymentType"
  | "paymentStatus"
  | "createdAt"
  | "plannedPaidAt"
  | "companyAccount"
  | "hasAct"
  | "hasSelfEmployedCheck"
  | "isServiceProvided"
  | "isOriginalsReceived"
  | "payTotalSumm"
  | "integrationId"
  | "integrationPrice"
  | "payPlannedPaidAt"
  | "payPaidAt"
  | "ord_hasAct"
  | "payDirection"
  | "paymentPaidSum"
  | "isPaymentOnCard"
  | "isVerifiedByGov";

const texts: allFiltersCode[] = ["promokod", "counteragent", "inn"];
const chips: allFiltersCode[] = [
  "id",
  "channel",
  "status",
  "channelTheme",
  "channelType",
  "size",
  "goods",
  "goodsCategory",
  "tz",
  "foundById",
  "releaseUserId",
  "releaseById",
  "paymentStatus",
  "typeCode",
  "theme",
  "agency",
  "channelId",
  "integrationId",
  "releaseUserId",
  "payStatus",
  "paymentType",
  "payDirection",
  "channeMocklId",
  "channelStatus",
];
const selects: allFiltersCode[] = [
  "firstIntegrationWithChannel",
  "edo",
  "operator",
  "counteragentType",
  "hasAct",
  "hasSelfEmployedCheck",
  "isServiceProvided",
  "isOriginalsReceived",
  "ord_hasAct",
  "isVerifiedByGov",
];
const multiples: allFiltersCode[] = [
  "roi",
  "cpv",
  "ctr",
  "cr",
  "crThrough",
  "validOrders",
  "canceledOrders",
  "views",
  "likes",
  "comments",
  "traffic",
  "prediction_orders",
  "prediction_views",
  "prediction_traffic",
  "paidSum",
  "price",
  "initialPrice",
  "audience",
  "targetAudience",
  "totalSumm",
  "integrationPrice",
  "payTotalSumm",
  "paymentPaidSum",
];
const checkboxs: allFiltersCode[] = [
  "hasGuarantee",
  "guaranteeNotCompleted",
  "isVideoReview",
  "isBanned",
  "isPaymentOnCard",
];
const radios: allFiltersCode[] = [];
const dates: allFiltersCode[] = [
  "integration_date",
  "plannedPaidAt",
  "paidAt",
  "dateFound",
  "dateGiven",
  "statisticDate",
  "createdAt",
  "payPlannedPaidAt",
  "payPaidAt",
];

export const filterSelectionStrategy = (item: IRenderFilterLists): IStrategyFiltersField => {
  if (texts.includes(item.code as allFiltersCode)) {
    return textsFilterStrategy(item);
  } else if (chips.includes(item.code as allFiltersCode)) {
    return chipsFilterStrategy(item);
  } else if (selects.includes(item.code as allFiltersCode)) {
    return selectsFilterStrategy(item);
  } else if (multiples.includes(item.code as allFiltersCode)) {
    return multiplesFilterStrategy(item);
  } else if (checkboxs.includes(item.code as allFiltersCode)) {
    return checkboxsFilterStrategy(item);
  } else if (radios.includes(item.code as allFiltersCode)) {
    return radiosFilterStrategy(item);
  } else if (dates.includes(item.code as allFiltersCode)) {
    return datesFilterStrategy(item);
  }
  return {} as IStrategyFiltersField;
};
