import { FC, memo } from "react";
import { AsideCardLayout } from "../../../../components/AsideCardLayout/AsideCardLayout";
import { Box, Stack, TextField, Typography, Link } from "@mui/material";
import { PaymentCountagentSelect } from "./ui/PaymentCountagentSelect/PaymentCountagentSelect";
import { IFinancesContrAgent } from "./IFinancesContrAgent";
import { EditModeButton } from "../../../../components/UI/Buttons/EditModeButton/EditModeButton";
import { EmptyText } from "../../../../components/EmptyText/EmptyText";
import { CopyIconButton } from "../../../../components/UI/Buttons/CopyIconButton/CopyIconButton";
import { Link as RouterLink } from "react-router-dom";

export const FinancesContrAgent: FC<IFinancesContrAgent> = memo((props) => {
  const {
    counteragent,
    setCounteragent,
    integrationContacts,
    disabled = false,
    isEditMode,
    isShowEditIcon,
    onCloseEditButton,
    onOpenEditButton,
    onSubmitEditButton,
    loadingEditButton,
    sxContAgentWidth,
    readOnly = false,
    setDocumentContact,
    setContractLink,
    documentContact,
    isViewEditModeFields,
  } = props;

  return (
    <AsideCardLayout
      headerTitle="контрагент"
      rightContent={
        isShowEditIcon &&
        onCloseEditButton &&
        onOpenEditButton &&
        onSubmitEditButton && (
          <EditModeButton
            isEdit={isEditMode}
            onClose={onCloseEditButton}
            onOpenEdit={onOpenEditButton}
            onSubmit={onSubmitEditButton}
            loading={loadingEditButton}
            disabledEdit={readOnly}
          />
        )
      }>
      <Box sx={{ mt: "6px" }}>
        <Box sx={sxContAgentWidth}>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            spacing={"6px"}
            alignItems={{ xs: "start", lg: "center" }}>
            <Typography>Контрагент:</Typography>
            {isEditMode && (
              <PaymentCountagentSelect
                setDocumentContact={setDocumentContact}
                setContractLink={setContractLink}
                value={counteragent}
                onChangeValue={setCounteragent}
                placeholder="Введите название или ИНН"
                disabled={disabled}
              />
            )}
            {!isEditMode && (
              <Typography noWrap>
                {counteragent?.shortName || <EmptyText>контрагент не выбран</EmptyText>}
              </Typography>
            )}
          </Stack>
        </Box>
        {counteragent && (
          <Stack spacing={"6px"} sx={{ mt: "6px" }}>
            <Typography display={"flex"} gap={1}>
              ИНН: {counteragent?.inn || <EmptyText>данные отсутствуют</EmptyText>}
            </Typography>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography display={"flex"} gap={1}>
                Прямой контакт по каналу:{" "}
                {integrationContacts?.filter((x) => x !== "").join(", ") || (
                  <EmptyText>данные отсутствуют</EmptyText>
                )}
              </Typography>
              {integrationContacts?.filter((x) => x !== "").join(", ") && (
                <CopyIconButton
                  copyText={integrationContacts?.filter((x) => x !== "").join(", ")}
                />
              )}
            </Stack>
            {!isEditMode && isViewEditModeFields && (
              <Stack direction={"row"} alignItems={"center"}>
                <Typography display={"flex"} gap={1} noWrap>
                  Контакт по документам:
                  {(counteragent?.contact?.documentContact && (
                    <Link
                      to={counteragent?.contact?.documentContact}
                      color={"secondary"}
                      component={RouterLink}
                      target="_blank"
                      sx={{ display: "inline-block", maxWidth: "200px" }}
                      noWrap>
                      {counteragent?.contact?.documentContact}
                    </Link>
                  )) || <EmptyText>данные отсутствуют</EmptyText>}
                </Typography>
                {counteragent?.contact?.documentContact && (
                  <CopyIconButton copyText={counteragent?.contact?.documentContact} />
                )}
              </Stack>
            )}
            {isEditMode && isViewEditModeFields && (
              <Stack alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
                <Typography>Контакт по документам:</Typography>
                <TextField
                  value={documentContact}
                  onChange={(e) => setDocumentContact && setDocumentContact(e.target.value)}
                  size="small"
                  placeholder="Введите значение"
                  fullWidth
                  FormHelperTextProps={{ style: { whiteSpace: "nowrap", marginLeft: 0 } }}
                  sx={{ maxWidth: "250px" }}
                />
              </Stack>
            )}
            <Typography display={"flex"} gap={1}>
              ЭДО:{" "}
              {counteragent?.edo === true
                ? "есть"
                : "нет" || <EmptyText>данные отсутствуют</EmptyText>}
            </Typography>
            <Typography display={"flex"} gap={1}>
              Оператор: {counteragent?.operator?.name || <EmptyText>данные отсутствуют</EmptyText>}
            </Typography>
          </Stack>
        )}
      </Box>
    </AsideCardLayout>
  );
});
