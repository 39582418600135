import { Checkbox, Stack } from "@mui/material";
import { FC, useState } from "react";
import { AlertModal } from "../../../../../../components/AlertModal/AlertModal";
import { IUpdateStatusesRequest } from "../../../../../../services/integration/IntegrationsRequest";
import { IVerifiedByGov } from "./IVerifiedByGov";
import { VerifiedIcon } from "./VerifiedIcon/VerifiedIcon";
import { observer } from "mobx-react-lite";
import integrationPageStore from "../../../../../../store/integrationPage.store";
import { useSearchParams } from "react-router-dom";
import { INTEGRATION_CARD_OPEN } from "../../../../../../shared/constants/searchParams";
import integrationTableStore from "../../../../../../store/integrationTable.store";

export const VerifiedByGov: FC<IVerifiedByGov> = observer((props) => {
  const [searchParams] = useSearchParams();

  const {
    isVerified,
    id,
    update_id,
    readOnly,
    onChangeUpdateId,
    setIsVerified,
    changeField,
    service,
    showCheckbox = true,
    audience,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);

  const handleOpenModal = () => {
    setIsShowAlert(true);
  };

  const changeVerifiyStatus = async () => {
    setLoading(true);

    const option: IUpdateStatusesRequest = {
      id,
      update_id,
      field: "isVerifiedByGov",
      new_value: !isVerified as any,
    };

    try {
      const res = await service.updateSimpleField(option);
      setIsVerified(res.data.isVerifiedByGov);
      changeField({
        id,
        update_id: res.data.update_id,
        field: "isVerifiedByGov",
        new_value: res.data.isVerifiedByGov as any,
      });
      onChangeUpdateId(res.data.update_id);
      setIsShowAlert(false);
      if (searchParams.has(INTEGRATION_CARD_OPEN)) {
        integrationPageStore.setIsVerifiedByGov(res.data.isVerifiedByGov);
        integrationTableStore.updateIsVerifiedByGov(id, res.data.isVerifiedByGov);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const subtext = isVerified
    ? "Вы уверены, что этот канал НЕ Зарегистрирован на сайте Госуслуги?"
    : "Вы уверены, что этот канал Зарегистрирован на сайте Госуслуги?";

  return (
    <Stack direction={"row"} alignItems={"center"}>
      {showCheckbox && (
        <Checkbox onClick={handleOpenModal} checked={isVerified} disabled={isLoading || readOnly} />
      )}
      <VerifiedIcon isVerified={isVerified} audience={audience} />
      {isShowAlert && (
        <AlertModal
          open={isShowAlert}
          title={"Внимание!"}
          subtext={subtext}
          onSubmit={changeVerifiyStatus}
          onClose={() => setIsShowAlert(false)}
          colorSubmit="error"
          buttonCancelName="Отмена"
          buttonSubmitName="Да"
        />
      )}
    </Stack>
  );
});
