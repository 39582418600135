import { makeAutoObservable } from "mobx";
import {
  IFinanceAdditionalAgreement,
  IFinanceContractBlock,
  IFinancePaymentsStatus,
  IOrdInfo,
  ITemplate,
} from "../services/payments/IPaymentsResponse";
import {
  IAvailableContractForPayment,
  IContractorCreateResponse,
  IContragentAttachment,
  IContragentContract,
} from "../services/contractor/IContractorIResponse";
import { IIntegrationsFullResponse } from "../services/integration/IIntegrationsResponse";
import { IPostedCommentItem } from "../components/PostedCommentItem/models/IPostedCommentItem";
import { IUser } from "../entities/user/models/IUser";
import { IEditContractDataRequest } from "../services/payments/IPaymentsRequest";
import { IFinancesPaymentItem } from "../pages/FinancesCreatePage/ui/FinancesPayments/ui/FinancesPaymentItem/IFinancesPaymentItem";
import { ILastPayInfo } from "../services/channel/ICnannelResponse";
import PaymentsService from "../services/payments/payments.service";

class FinanceCardPageStore {
  chatOpen: boolean = true;

  readOnly: boolean = false;

  canceledOrDeletedIntegrations: number[] | null = null;

  contractBlock: IFinanceContractBlock | null = null;

  contract: IContragentContract | null = null;
  attachment: IContragentAttachment | null = null;
  availableContractsForPayment: IAvailableContractForPayment[] = [];
  trackNumber: string | null = null;
  isOriginalsReceived: boolean = false;
  additionalAgreement: IFinanceAdditionalAgreement | null = null;

  editContractBlock: IEditContractDataRequest | null = null;

  counteragent: IContractorCreateResponse | null = null;
  createdAt: string | null = null;
  createdBy: IUser | null = null;
  hasAct: boolean | null = null;
  id: number | null = null;
  integrations: IIntegrationsFullResponse[] | null = null;
  isServiceProvided: boolean | null = null;
  pays: IFinancesPaymentItem[] | null = null;
  postedComments: IPostedCommentItem[] | null = null;
  releaseUserId: string | null = null;
  status: IFinancePaymentsStatus | null = null;
  totalSumm: number | null = null;
  updatedAt: string | null = null;
  updatedBy: null | number = null;
  paymentType: { code: string; name: string } | null = null;
  actLink: string | null = null;
  selfEmployedCheckLink: string[] | null = null;
  hasSelfEmployedCheck: boolean | null = null;
  plannedPaidAt: string | null = null;
  paidAt: string | null = null;
  template: ITemplate[] = [];

  editPays: IFinancesPaymentItem[] | null = null;

  edithasAct: boolean | null = null;

  editIsServiceProvided: boolean | null = null;

  editActLink: null | string = null;

  editSelfEmployedCheckLink: null | string[] = null;

  editHasSelfEmployedCheck: null | boolean = null;

  editPlannedPaidAt: null | string = null;

  editReleaseUserId: null | string = null;

  editCounteragent: IContractorCreateResponse | null = null;

  ordInfoBlock: IOrdInfo[] | null = null;
  editOrdInfoBlock: IOrdInfo[] | null = null;

  lastPayInfo: null | ILastPayInfo = null;

  update_id: number | null = null;

  errorPayments: string | null = null;

  isCheckGuaranteeCompleteAll: boolean | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setTrackNumber = (value: string) => {
    this.trackNumber = value;
  };

  setIsOriginalsReceived = (value: boolean) => {
    this.isOriginalsReceived = value;
  };

  setAdditionalAgreement = (agreement: IFinanceAdditionalAgreement) => {
    this.additionalAgreement = agreement;
  };

  setChatOpen = (chatOpen: boolean) => {
    this.chatOpen = chatOpen;
  };

  setIsCheckGuaranteeCompleteAll = (isCheckGuaranteeCompleteAll: boolean | null) => {
    this.isCheckGuaranteeCompleteAll = isCheckGuaranteeCompleteAll;
  };

  setId = (id: number | null) => {
    this.id = id;
  };

  setUpdateId = (update_id: number | null) => {
    this.update_id = update_id;
  };

  setErrorPayments = (errorPayments: string | null) => {
    this.errorPayments = errorPayments;
  };

  setContractBlock = (contractBlock: IFinanceContractBlock | null) => {
    this.contractBlock = contractBlock;
  };

  setContract(contract: IContragentContract | null) {
    this.contract = contract;
  }

  setAttachment(attachment: IContragentAttachment | null) {
    this.attachment = attachment;
  }

  setAvailableContractsForPayment(availableContractsForPayment: IAvailableContractForPayment[]) {
    this.availableContractsForPayment = availableContractsForPayment;
  }

  // setContractFieldValue<K extends keyof IContragentContract>(key: K, value: )

  setEditContractBlock = <K extends keyof IEditContractDataRequest>(
    key: K,
    value: IEditContractDataRequest[K]
  ) => {
    this.editContractBlock = {
      ...this.editContractBlock,
      [key]: value === "" ? null : value,
    };
  };

  setEditAdditionalAgreementField = <K extends keyof IFinanceAdditionalAgreement>(
    key: K,
    value: IFinanceAdditionalAgreement[K]
  ) => {
    this.editContractBlock = {
      ...this.editContractBlock,
      additionalAgreement: {
        ...this.editContractBlock?.additionalAgreement,
        [key]: value,
      },
    };
  };

  updateContractBlock = (id: number, editContractBlock: IEditContractDataRequest) => {
    return PaymentsService.updatePaymentContractBlock(id.toString(), editContractBlock).then(
      (res) => {
        const {
          contract,
          attachment,
          additionalAgreement,
          trackNumber,
          isOriginalsReceived,
          update_id,
        } = res.data;

        this.setContract(contract);
        this.setAttachment(attachment);
        this.setAdditionalAgreement(additionalAgreement);
        this.setTrackNumber(trackNumber);
        this.setIsOriginalsReceived(isOriginalsReceived);
        this.setUpdateId(update_id);
      }
    );
  };

  setOrdInfoBlock = (ordInfoBlock: IOrdInfo[] | null) => {
    this.ordInfoBlock = ordInfoBlock;
  };

  setCanceledOrDeletedIntegrations = (canceledOrDeletedIntegrations: number[] | null) => {
    this.canceledOrDeletedIntegrations = canceledOrDeletedIntegrations;
  };
  setTemplate = (template: ITemplate[]) => {
    this.template = template;
  };

  setCounteragent = (counteragent: IContractorCreateResponse | null) => {
    this.counteragent = counteragent;

    if (this.pays) {
      this.pays = this.pays?.map((item) => {
        if (item?.paymentType === "bank" || item?.paymentType === "netting") {
          return { ...item, direction: counteragent?.id?.toString() || null };
        } else return item;
      });
    }

    if (this.editPays) {
      this.editPays = this.editPays?.map((item) => {
        if (item?.paymentType === "bank" || item?.paymentType === "netting") {
          return { ...item, direction: counteragent?.id?.toString() || null };
        } else return item;
      });
    }
  };

  setLastPayInfo = (lastPayInfo: ILastPayInfo | null) => {
    this.lastPayInfo = lastPayInfo;

    if (this.editPays && this.editPays?.length > 0) {
      this.editPays = this.editPays.map((item) => {
        if (item.paymentType === "card") {
          return {
            ...item,
            cardOrPhone: this.lastPayInfo?.phoneOrCard?.replaceAll(" ", "") || null,
            fio: this.lastPayInfo?.fio || null,
          };
        } else return item;
      });
    }
  };

  setReadOnly = (readOnly: boolean) => {
    this.readOnly = readOnly;
  };

  setEditCounteragent = (editCounteragent: IContractorCreateResponse | null) => {
    this.editCounteragent = editCounteragent;
  };

  setCeatedBy = (createdBy: IUser | null) => {
    this.createdBy = createdBy;
  };

  setCeatedAt = (createdAt: string | null) => {
    this.createdAt = createdAt;
  };

  setPlannedPaidAt = (plannedPaidAt: string | null) => {
    this.plannedPaidAt = plannedPaidAt;
  };

  setPaidAt = (paidAt: string | null) => {
    this.paidAt = paidAt;
  };

  setEditPlannedPaidAt = (editPlannedPaidAt: string | null) => {
    this.editPlannedPaidAt = editPlannedPaidAt;
  };

  setHasAct = (hasAct: boolean | null) => {
    this.hasAct = hasAct;
  };

  setSelfEmployedCheckLink = (selfEmployedCheckLink: string[] | null) => {
    this.selfEmployedCheckLink = selfEmployedCheckLink;
  };

  setActLink = (actLink: string | null) => {
    this.actLink = actLink;
  };

  setHasSelfEmployedCheck = (hasSelfEmployedCheck: boolean | null) => {
    this.hasSelfEmployedCheck = hasSelfEmployedCheck;
  };

  setPaymentType = (paymentType: { code: string; name: string } | null) => {
    this.paymentType = paymentType;
  };

  setIntegrations = (integrations: IIntegrationsFullResponse[] | null) => {
    this.integrations = integrations;
  };

  addIntegrations = (integration: IIntegrationsFullResponse) => {
    if (this.integrations) {
      this.integrations = [...this.integrations, integration];
    }
  };

  deleteIntegrations = (id: number) => {
    if (this.integrations) {
      this.integrations = this.integrations?.filter((x) => x.id !== id);
    }
  };

  setServiceProvided = (isServiceProvided: boolean | null) => {
    this.isServiceProvided = isServiceProvided;
  };

  setPays = (pays: IFinancesPaymentItem[] | null) => {
    this.pays = pays;
  };

  setPostedComments = (postedComments: IPostedCommentItem[] | null) => {
    this.postedComments = postedComments;
  };

  addPostedComments = (postedComments: IPostedCommentItem) => {
    if (this.postedComments) {
      this.postedComments = [...this.postedComments, postedComments];
    }
  };

  editComment = (id: number, comment: IPostedCommentItem) => {
    if (this.postedComments) {
      this.postedComments = this.postedComments.map((item) => {
        if (item.id === id) {
          return comment;
        } else return item;
      });
    }
  };

  deletePostedComments = (id: number) => {
    if (this.postedComments) {
      this.postedComments = this.postedComments.filter((item) => item.id !== id);
    }
  };

  setStatus = (status: IFinancePaymentsStatus | null) => {
    this.status = status;
  };

  setTotalSumm = (totalSumm: number | null) => {
    this.totalSumm = totalSumm;
  };

  setReleaseUserId = (releaseUserId: string | null) => {
    this.releaseUserId = releaseUserId;
  };

  setEditReleaseUserId = (editReleaseUserId: string | null) => {
    this.editReleaseUserId = editReleaseUserId;
  };

  syncEditPaysWithPays = () => {
    if (this.pays) {
      this.editPays = [...this.pays];
    }
  };

  clearEditPays = () => {
    this.editPays = null;
  };

  addPayItemInEditPays = () => {
    const allElems = this.editPays?.length || this.pays?.length;

    const pay: IFinancesPaymentItem = {
      paymentType: "bank",
      direction: this.counteragent?.id?.toString() || null,
      fio: null,
      paymentLink: null,
      plannedPaidAt: null,
      selfEmployedCheck: null,
      source: "ip-bokova",
      totalSumm: allElems === 0 ? this.totalSumm : null,
      id: -99999999 - (allElems || 0),
      cardOrPhone: null,
      status: { code: "not_paid" } as any,
    };

    if (this.editPays === null && this.pays) {
      this.editPays = [...this.pays, pay];
    } else if (this.editPays !== null) {
      this.editPays = [...this.editPays, pay];
    }
  };

  changeEditPaymentType = (id: number, field: string, typeValue: string) => {
    if (this.editPays) {
      this.editPays = this.editPays?.map((item) => {
        if (item.id === id) {
          if (typeValue === "card") {
            const pay: IFinancesPaymentItem = {
              id: item?.id,
              direction: null,
              paymentType: "card",
              plannedPaidAt: item.plannedPaidAt,
              source: null,
              totalSumm: item.totalSumm,
              cardOrPhone: this.lastPayInfo?.phoneOrCard?.replaceAll(" ", "") || null,
              fio: this.lastPayInfo?.fio || null,
              paymentLink: null,
              selfEmployedCheck: null,
              status: item.status || undefined,
            };

            return pay;
          } else {
            const pay: IFinancesPaymentItem = {
              id: item?.id,
              direction: this.counteragent?.id?.toString() || null,
              paymentType: typeValue,
              plannedPaidAt: item.plannedPaidAt,
              source: "ip-bokova",
              totalSumm: item.totalSumm,
              cardOrPhone: null,
              fio: null,
              paymentLink: null,
              selfEmployedCheck: null,
              status: item.status || undefined,
            };

            return pay;
          }
        } else return item;
      });
    }
  };

  changeSimpleFielEditPay = (id: number, field: string, newValue: string) => {
    if (this.editPays) {
      this.editPays = this.editPays?.map((item) => {
        if (item.id === id) {
          return { ...item, [field]: newValue };
        } else return item;
      });
    }
  };

  onChangePayStatus = (
    id: number,
    status: string,
    paidAt: string | null,
    oplataPaidAt?: string | null
  ) => {
    this.paidAt = oplataPaidAt !== undefined ? oplataPaidAt : this.paidAt;

    if (this.pays) {
      this.pays = this.pays?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status: { ...item?.status, code: status } as any,
            paidAt: paidAt as any,
          };
        } else return item;
      });
    }
  };

  onChangeEditStatus = (id: number, status: string, paidAt: string | null) => {
    if (this.editPays) {
      this.editPays = this.editPays?.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status: { ...item?.status, code: status } as any,
            paidAt: paidAt as any,
          };
        } else return item;
      });
    }
  };

  syncContractWithEditContract = () => {
    this.editContractBlock = {
      ...this.editContractBlock,
      contractId: this.contract?.id,
      contractLink: this.contract?.link,
      contractSumm: this.contract?.summ,
      contractComment: this.contract?.contractComment,
      attachmentId: this.attachment?.id,
      attachmentLink: this.attachment?.link,
      actId: this.attachment?.act?.id,
      trackNumber: this.trackNumber,
      isOriginalsReceived: this.isOriginalsReceived,
      additionalAgreement: this.additionalAgreement,
    };
  };

  closeContarctEdit = () => {
    this.editContractBlock = null;
  };

  removeAttachment = () => {
    this.editContractBlock = {
      ...this.editContractBlock,
      attachmentId: null,
    };
  };

  removeAdditionalAgreement = () => {
    this.editContractBlock = {
      ...this.editContractBlock,
      additionalAgreement: null,
    };
  };

  startEditClosingDocs = () => {
    this.edithasAct = this.hasAct;
    this.editIsServiceProvided = this.isServiceProvided;
    this.editActLink = this.actLink;
    this.editSelfEmployedCheckLink = this.selfEmployedCheckLink;
    this.editHasSelfEmployedCheck = this.hasSelfEmployedCheck;
  };

  closeEditClosingDocs = () => {
    this.edithasAct = null;
    this.editActLink = null;

    this.editHasSelfEmployedCheck = null;
    this.editSelfEmployedCheckLink = null;

    this.editIsServiceProvided = null;
  };

  changeEditHasAct = (checked: boolean) => {
    this.edithasAct = checked;
  };

  changeEditActLink = (editActLink: string) => {
    if (editActLink?.trim() !== "") {
      this.edithasAct = true;
    } else {
      this.edithasAct = false;
    }

    this.editActLink = editActLink;
  };

  changeEditSelfEmployedCheckLink = (editSelfEmployedCheckLink: string[]) => {
    if (editSelfEmployedCheckLink?.length !== 0) {
      this.editHasSelfEmployedCheck = true;
    } else {
      this.editHasSelfEmployedCheck = false;
    }

    this.editSelfEmployedCheckLink = editSelfEmployedCheckLink;
  };

  changeEditHasSelfEmployedCheck = (editHasSelfEmployedCheck: boolean) => {
    this.editHasSelfEmployedCheck = editHasSelfEmployedCheck;
  };

  changeEditServiceProvided = (checked: boolean) => {
    this.editIsServiceProvided = checked;
  };

  startEditOplata = () => {
    this.editReleaseUserId = this.releaseUserId;
    this.editPlannedPaidAt = this.plannedPaidAt;
  };

  closeEditOplatat = () => {
    this.editReleaseUserId = null;
    this.editPlannedPaidAt = null;
  };

  startEditCounterAgent = () => {
    this.editCounteragent = this.counteragent;
  };

  closeEditCounterAgent = () => {
    this.editCounteragent = null;
  };

  startEditOrdInfoBlock = () => {
    this.editOrdInfoBlock = this.ordInfoBlock;
  };

  closeEditOrdInfoBlock = () => {
    this.editOrdInfoBlock = null;
  };

  changeEditCheckbox = (id: number, field: string, checked: boolean) => {
    if (this.editOrdInfoBlock) {
      this.editOrdInfoBlock = this.editOrdInfoBlock?.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            [field]: checked,
          };
        } else return item;
      });
    }
  };

  setFormatSelect = (id: number, formatCode: string) => {
    if (this.editOrdInfoBlock) {
      this.editOrdInfoBlock = this.editOrdInfoBlock?.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            format: {
              name: item?.format?.name || undefined,
              code: formatCode,
            },
          };
        } else return item;
      });
    }
  };

  changeSimpleEditInput = (id: number, field: string, value: string | number, type: string) => {
    if (this.editOrdInfoBlock) {
      this.editOrdInfoBlock = this.editOrdInfoBlock?.map((item) => {
        if (item?.id === id) {
          return {
            ...item,
            [field]: value,
          };
        } else return item;
      });
    }
  };

  closeCardPage = () => {
    this.contractBlock = null;
    this.counteragent = null;
    this.createdAt = null;
    this.createdBy = null;
    this.hasAct = null;
    this.id = null;
    this.integrations = null;
    this.isServiceProvided = null;
    this.pays = null;
    this.postedComments = null;
    this.releaseUserId = null;
    this.status = null;
    this.totalSumm = null;
    this.paymentType = null;
    this.paidAt = null;
    this.updatedAt = null;
    this.updatedBy = null;
    this.editPays = null;
    this.editContractBlock = null;
    this.edithasAct = null;
    this.editIsServiceProvided = null;
    this.actLink = null;
    this.selfEmployedCheckLink = null;
    this.hasSelfEmployedCheck = null;
    this.editActLink = null;
    this.editSelfEmployedCheckLink = null;
    this.editHasSelfEmployedCheck = null;
    this.plannedPaidAt = null;
    this.editPlannedPaidAt = null;
    this.editReleaseUserId = null;
    this.editCounteragent = null;

    this.readOnly = false;
    this.canceledOrDeletedIntegrations = null;

    this.ordInfoBlock = null;
    this.editOrdInfoBlock = null;
    this.lastPayInfo = null;

    this.update_id = null;
    this.errorPayments = null;
  };
  deleteEditPaymentType = (id: number) => {
    if (this.editPays) {
      this.editPays = this.editPays?.filter((x) => x.id !== id);
    }
  };

  resetEditOrdInfo = () => {
    if (Array.isArray(this.editOrdInfoBlock)) {
      this.editOrdInfoBlock = this.editOrdInfoBlock.map((item) => ({
        ...item,
        token: "",
        linkToCreative: "",
        hasAct: false,
        format: { ...item.format, code: "" },
      }));
    }
  };
}

export default new FinanceCardPageStore();
