import { FC, useState } from "react";
import { IChannelPopUp } from "./IChannelPopUp";
import { ChannelNamePopUp } from "../ChannelNamePopUp/ChannelNamePopUp";
import ChannelService from "../../../../../services/channel/channel.service";
import { IUpdateStatusesRequest } from "../../../../../services/integration/IntegrationsRequest";
import { AxiosError } from "axios";
import channelTableStore from "../../../../../store/channelTable.store";

export const ChannelPopUp: FC<IChannelPopUp> = (props) => {
  const {
    channelType,
    link,
    name,
    channelId,
    showChannelButton = false,
    update_id,
    readOnly = false,
    isBanned,
    showArrowLink,
    showPaymentOnCardIcon,
    showVerifiedIcon,
    audience
  } = props;

  const [isOpenEdit, setOpenEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const submitEditLink = (linkValue: string) => {
    setLoading(true);
    const option: IUpdateStatusesRequest = {
      field: "link",
      id: channelId,
      new_value: linkValue,
      update_id,
    };
    ChannelService.updateSimpleField(option)
      .then((res) => {
        // console.log(res, 'res upodate link')

        const optionStore: IUpdateStatusesRequest = {
          field: "link",
          id: channelId,
          new_value: linkValue,
          update_id: res.data.update_id,
        };

        channelTableStore.changeField(optionStore);
        setOpenEdit(false);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err link edit')
          setLoading(false);
        }
      });
  };

  return (
    <ChannelNamePopUp
      showArrowLink={showArrowLink}
      channelId={channelId}
      name={name}
      link={link}
      channelType={channelType}
      openEditModal={isOpenEdit}
      onCloseEditModal={() => setOpenEdit(false)}
      onOpenEditModal={() => setOpenEdit(true)}
      onLoadingModalEdit={isLoading}
      onSubmitEdit={submitEditLink}
      readOnly={readOnly}
      isBanned={isBanned}
      showPaymentOnCardIcon={showPaymentOnCardIcon}
      showVerifiedIcon={showVerifiedIcon} 
      audience={audience}
    />
  );
};
