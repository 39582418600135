import { IChannelTheame } from "../../../../../entities/ChannelTheame/models/IChannelTheame";
import { IChannelUrlFilters } from "../../../../../services/channel/IChannelRequest";
import { IChannelMinMaxResponse } from "../../../../../services/channel/ICnannelResponse";
import { IFromToArray } from "../../../../../services/integration/IntegrationsRequest";
import { IFilterMultiple } from "../../../../Filters/UI/FilterMultiple/IFilterMultiple";
import { IFilterSelectItem } from "../../../../Filters/UI/FilterSelectChip/IFilterSelectChip";

export const getChannelTheameFilters = (channelsTheames: IChannelTheame[]): IFilterSelectItem[] => {
  const theame: IFilterSelectItem[] = channelsTheames.map((theame) => {
    return { name: theame.name, value: theame.code };
  });

  return theame.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
};

export const getAllFilterMultiple = (
  dataMinMax: IChannelMinMaxResponse,
  isLoading: boolean
): IFilterMultiple[] => {
  const allFilters: IFilterMultiple[] = [
    {
      initValue: dataMinMax?.price,
      label: "Цена ₽",
      searchParamName: "price",
      isLoading,
    },
    {
      initValue: dataMinMax?.initialPrice,
      label: "Стоимость ₽",
      searchParamName: "initialPrice",
      isLoading,
    },
    {
      initValue: dataMinMax?.roi,
      label: "ROI %",
      searchParamName: "roi",
      isLoading,
    },
    {
      initValue: dataMinMax?.cpv,
      label: "Стоимость CPV ₽",
      searchParamName: "cpv",
      isLoading,
      step: 0.1,
    },
    {
      initValue: dataMinMax?.ctr,
      label: "Конверсия рекламы CTR %",
      searchParamName: "ctr",
      isLoading,
      step: 0.1,
    },
    {
      initValue: dataMinMax?.cr,
      label: "Коэффицент конверсии CR %",
      searchParamName: "cr",
      isLoading,
      step: 0.1,
    },
    {
      initValue: dataMinMax?.validOrders,
      label: "Кол-во заказов",
      searchParamName: "orders",
      isLoading,
    },
    {
      initValue: dataMinMax?.canceledOrders,
      label: "Кол-во отмен",
      searchParamName: "canceledOrders",
      isLoading,
    },
    {
      initValue: dataMinMax?.views,
      label: "Кол-во просмотров",
      searchParamName: "views",
      isLoading,
    },
    {
      initValue: dataMinMax?.traffic,
      label: "Трафик",
      searchParamName: "traffic",
      isLoading,
    },
    {
      initValue: dataMinMax?.audience,
      label: "Кол-во подписчиков",
      searchParamName: "audience",
      isLoading,
    },
    {
      initValue: [0, 100],
      label: "Целевая аудитория %",
      searchParamName: "targetAudience",
      isLoading,
    },
  ];

  return allFilters;
};

export const getFilterChannelQuery = (searchQuery: URLSearchParams): IChannelUrlFilters => {
  const transormValueToArrayFromTo = (
    searchArr: string[],
    isDate?: boolean
  ): IFromToArray<number | string> => {
    const array = !isDate ? searchArr.map((x) => +x) : searchArr;

    const fromToArray: IFromToArray<number | string> = {
      from: array[0],
      to: array[1],
    };
    return fromToArray;
  };

  const option: IChannelUrlFilters | any = {
    id: searchQuery.get("id")
      ? JSON.parse(searchQuery.get("id")!)?.map((x: IFilterSelectItem) => x.value)
      : null,
    foundById: searchQuery.get("foundById")?.split(",") || null,
    releaseById: searchQuery.get("releaseById")?.split(",") || null,
    roi: searchQuery.get("roi")
      ? transormValueToArrayFromTo(searchQuery.get("roi")?.split(",")!)
      : null,
    cpv: searchQuery.get("cpv")
      ? transormValueToArrayFromTo(searchQuery.get("cpv")?.split(",")!)
      : null,
    ctr: searchQuery.get("ctr")
      ? transormValueToArrayFromTo(searchQuery.get("ctr")?.split(",")!)
      : null,
    cr: searchQuery.get("cr")
      ? transormValueToArrayFromTo(searchQuery.get("cr")?.split(",")!)
      : null,
    validOrders: searchQuery.get("orders")
      ? transormValueToArrayFromTo(searchQuery.get("orders")?.split(",")!)
      : null,
    canceledOrders: searchQuery.get("canceledOrders")
      ? transormValueToArrayFromTo(searchQuery.get("canceledOrders")?.split(",")!)
      : null,
    views: searchQuery.get("views")
      ? transormValueToArrayFromTo(searchQuery.get("views")?.split(",")!)
      : null,
    traffic: searchQuery.get("traffic")
      ? transormValueToArrayFromTo(searchQuery.get("traffic")?.split(",")!)
      : null,
    price: searchQuery.get("price")
      ? transormValueToArrayFromTo(searchQuery.get("price")?.split(",")!)
      : null,
    initialPrice: searchQuery.get("initialPrice")
      ? transormValueToArrayFromTo(searchQuery.get("initialPrice")?.split(",")!)
      : null,
    agency: searchQuery.get("agency")?.split(",") || null,
    theme: searchQuery.get("theme")?.split(",") || null,
    advertiser: searchQuery.get("advertiser")?.split(",") || null,
    searchSystem: searchQuery.get("searchSystem")?.split(",") || null,
    targetAudience: searchQuery.get("targetAudience")
      ? transormValueToArrayFromTo(searchQuery.get("targetAudience")?.split(",")!)
      : null,
    status: searchQuery.get("status")?.split(",") || null,
    typeCode: searchQuery.get("typeCode")?.split(",") || null,
    size: searchQuery.get("size")?.split(",") || null,
    dateFound: searchQuery.get("dateFound")
      ? transormValueToArrayFromTo(searchQuery.get("dateFound")?.split(",")!, true)
      : null,
    dateGiven: searchQuery.get("dateGiven")
      ? transormValueToArrayFromTo(searchQuery.get("dateGiven")?.split(",")!, true)
      : null,
    statisticDate: searchQuery.get("statisticDate")
      ? transormValueToArrayFromTo(searchQuery.get("statisticDate")?.split(",")!, true)
      : null,
    audience: searchQuery.get("audience")
      ? transormValueToArrayFromTo(searchQuery.get("audience")?.split(",")!)
      : null,
    isBanned: searchQuery.get("isBanned") ? searchQuery.get("isBanned") === "true" : null,
    isPaymentOnCard: searchQuery.get("isPaymentOnCard")
      ? searchQuery.get("isPaymentOnCard") === "true"
      : null,
    isVerifiedByGov: searchQuery.get("isVerifiedByGov")
      ? searchQuery.get("isVerifiedByGov") === "true"
      : null,
  };

  const filterEmptyFieldOption: any = {};

  for (let key in option) {
    if (option[key]) {
      filterEmptyFieldOption[key] = option[key];
    }
  }

  return filterEmptyFieldOption;
};
