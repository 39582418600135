import {useState, useEffect} from 'react'
import { TableDefault } from '../Table/TableDefault'
import { IntegrationTableSettings } from '../IntegrationTable/ui/IntegrationTableSettings'
import { observer } from 'mobx-react-lite'
import ChannelService from '../../services/channel/channel.service'
import { IChannelRequest, allChannelTableCol } from '../../services/channel/IChannelRequest'
import userStore from '../../store/user.store'
import channelTableStore from '../../store/channelTable.store'
import { AxiosError, AxiosProgressEvent } from 'axios'
import { ISettingsSelected } from '../IntegrationTable/models/ISettingsSelected'
import { toJS } from 'mobx'
import { IFiltersUpadteRequest } from '../../services/integration/IntegrationsRequest'
import { selectionStretegy } from '../../strategy/strategy'
import IntegrationsService from '../../services/integration/integrations.service'
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material'
import { NotificationAlerts } from '../NotificationAlerts/NotificationAlerts'
import {LS_TAKE_REQUEST_CHANNEL} from '../../shared/constants/localstorage'
import {Route, Routes, useSearchParams} from 'react-router-dom'
import { setCheckedParams } from '../../store/integrationTable.store'
import { getFilterChannelQuery } from './ui/ChannelFiter/shared/dataList'
import { ChannelTableList } from './ui/ChannelTableList'
import { downloadExcel } from '../../shared/functions/functions'
import { NotificationProgress } from '../NotificationProgress/NotificationProgress'
import {ChannelFilter} from "./ui/ChannelFiter/ChannelFilter";
import {channelMainSectionRoutes} from "../../shared/routes/routes";

export const ChannelTable = observer(() => {

  const [searchParams,setSearchParams] = useSearchParams()

  const [tableHead, setTableHead] = useState<any[]>([])
  const [currentSortType, setCurrentSortType] = useState('')
  const [isSettingsOpen, setSettingsOpen] = useState(false)

  const [isLoadingSettings, setLoadingSettings] = useState(false)

  const [propgressExport, setPropgressExport] = useState(0)
  const [isExcelLoading, setExcelLoading] = useState(false)

  const [tableError, setTableError] = useState<string | null>(null)

  const [showAlert, setShowAlert] = useState(false)
  const [errorAlert, setErrorAlert] = useState<string | null>(null)
    
  const [takeParamRequest, setTakeParamRequest] = useState(() => 
    [`${localStorage.getItem(LS_TAKE_REQUEST_CHANNEL) ? localStorage.getItem(LS_TAKE_REQUEST_CHANNEL) : 100}`]
  )

  const onChaneTakeRequest = (event: React.MouseEvent<HTMLElement>,  newFormats: string[]) => {
    if(newFormats !== null) {
      setTakeParamRequest(newFormats)
      localStorage.setItem(LS_TAKE_REQUEST_CHANNEL, `${newFormats}`)
      if (searchParams.has('page')){
        searchParams.set('page', '1');
        setSearchParams(searchParams);
      }
      searchParams.set('take',newFormats as any);
      setSearchParams(searchParams);
      channelTableStore.setPage(1)
    } else return
  }

  const sendSaveSettings = async (array:ISettingsSelected[]) => {
    setLoadingSettings(true)
    setErrorAlert(null)
    
    const options:IFiltersUpadteRequest = {
      entity:'channel',
      new_settings:array
    }
    try {
      const res = await IntegrationsService.getUpdateFilters(options)
      // console.log(res.data, 'res data upadate filterrs')
    
      channelTableStore.setSelectedList(res.data)
      channelTableStore.setInitSettingsSelect(res.data)
    
      const totalArr:ISettingsSelected[] = []
    
      for(let i = 0; i < res.data.length; i++){
        totalArr.push(...res.data[i].fields)
      }
    
      totalArr.sort((a,b)=>a.ordering > b.ordering ? 1 : -1)
    
      channelTableStore.setConfirmList([...totalArr].filter(x=>Boolean(x.isSelected)))
      channelTableStore.setInitSettingsConfirm([...totalArr].filter(x=>Boolean(x.isSelected)))
    
      const rows:any[] = []
      toJS(channelTableStore).confirmLists.map(field=>rows.push(selectionStretegy(field)))
      setTableHead(rows)

      setLoadingSettings(false)
      setSettingsOpen(false)
      setShowAlert(true)
    } catch (err) {
      if (err instanceof AxiosError) {
          console.log(err)
          setErrorAlert(err.message)
          setLoadingSettings(false)
        }
      }
    }
    
    const submitSettings = () => {
      sendSaveSettings(channelTableStore.confirmLists)
    }
    
    const resetSettings = () => {
      const store = toJS(channelTableStore)

      channelTableStore.setConfirmList(store.initSettingsConfirm)
      channelTableStore.setSelectedList(store.initSettingsSelect)
    }
    
    const deleteConfirmItem = (code:string) => {
      channelTableStore.onConfirmDelete(code)
      channelTableStore.removeConfirmList(code)
    }
      
    const getChannelData = async (page:number) => {
      channelTableStore.setLoading(true)
      setTableError(null)
        
      const take = localStorage.getItem(LS_TAKE_REQUEST_CHANNEL) ? localStorage.getItem(LS_TAKE_REQUEST_CHANNEL) : 100
      const params:IChannelRequest = {
        includeInSelect:channelTableStore.allFilter as allChannelTableCol[],
        take:+`${take}`,
        company:userStore.currentCompany,
        page,
        filterQuery:getFilterChannelQuery(searchParams)
      }
      try {
        const res = await ChannelService.getChannelItems(params)
        // console.log(res, 'res Channel table DATA')
    
        const rows:any[] = []
        toJS(channelTableStore).confirmLists.map(field=>rows.push(selectionStretegy(field)))

        setTableHead(rows)

        channelTableStore.setTableItems(res.data.items)
        channelTableStore.setTotalCount(res.data.totalCount)
        channelTableStore.setLoading(false)
      } catch(err) {
        if(err instanceof AxiosError){
          // console.log(err, 'err Channel data')
          setTableError(err.message)
          channelTableStore.setLoading(false)
        }
      }
    }

  const addConfirmList = (item:ISettingsSelected) => channelTableStore.addToConfirmList(item)
  const removeConfirmArr = (id:string) => channelTableStore.removeConfirmList(id)
  const setSelectChecked = (params:setCheckedParams) => channelTableStore.setSelectedChecked(params)
  const sortHandler = (type:string, sortType:'asc' | 'desc') => channelTableStore.sortTableItem(type, sortType)
  
  const changePage = (page:number) =>{
    channelTableStore.setPage(page)
    getChannelData(page)
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  }

  const onDownloadProgress = (progressEvent:AxiosProgressEvent) => {
    if(progressEvent.estimated && progressEvent.total){
      const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
      setPropgressExport(prev=>progress)
    }
  }

  const onExportExcel = () => {
    setExcelLoading(true)
    setPropgressExport(prev=>0)

    

    const params:IChannelRequest = {
      includeInSelect:channelTableStore.allFilter as allChannelTableCol[],
      take:+`${100}`,
      company:userStore.currentCompany,
      page:1,
      filterQuery:getFilterChannelQuery(searchParams)
    }
    ChannelService.channelsFilteredExcel(params, onDownloadProgress)
      .then(res=>{
        downloadExcel(res.data, 'Каналы')
        setPropgressExport(prev=>0)
      })
      .catch(err=>{
        if(err instanceof AxiosError){
          console.log(err, 'download excel err')
        }
      })
      .finally(()=>{
        setExcelLoading(false)
      })
  }

  const getFilteredChannel = () => {
    const currentPage = Number(searchParams.get('page')) || 1
    getChannelData(currentPage)
    channelTableStore.setPage(currentPage)
  }

  const totalPages = Math.ceil(channelTableStore.totalCount / (Array.isArray(takeParamRequest) ? +takeParamRequest[0] : takeParamRequest))

  const allowedPageSizes = [100 , 300 , 500]

  const isPageSizeAllowed = allowedPageSizes.some(number => searchParams.get('take') === number.toString());

  useEffect(() => {
    getFilteredChannel()
    return () => {
      channelTableStore.setTableItems([])
    }
  }, [takeParamRequest])


  useEffect(() => {
    const takeFromUrl =   Number(searchParams.get('take'))
    if (searchParams.has('take')){
      localStorage.setItem(LS_TAKE_REQUEST_CHANNEL, `${takeFromUrl}`)
      setTakeParamRequest([takeFromUrl.toString()])
    }
    if (searchParams.has('take') && !isPageSizeAllowed) {
      channelTableStore.setIsPageNotFound(true)
    }
  }, [])

  useEffect(()=>{
    if ( totalPages && searchParams.has('page') && Number(searchParams.get('page')) > totalPages ) {
      channelTableStore.setIsPageNotFound(true)
    }
  },[totalPages])

  return (
    <>
      <ChannelFilter/>
      {isExcelLoading && <NotificationProgress propgress={propgressExport}/>}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={
          channelTableStore.isLoading === false && tableError === null && channelTableStore.isLoadingSort && 
          !!channelTableStore.tableItems?.length && (currentSortType !=='' || searchParams.get('sort')) ? true : false}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>
      {channelTableStore.isLoading &&
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', mt:2}}>
          Идёт загрузка таблицы... <CircularProgress sx={{ml:2}}/>
        </Box>
      }
      {channelTableStore.isLoading === false && tableError &&
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', mt:2}}>
          <Typography variant='h5' color='error'>Ошибка с таблицей! {tableError}</Typography>
        </Box>
      }
      {channelTableStore.isLoading === false && tableError === null &&
        <>
          <TableDefault
            tableRow={tableHead}
            showBurgerCell
            settingsOnclick={()=>setSettingsOpen(true)}
            totalItems={channelTableStore.tableItems.length}
            showFooter
            isShowSort
            currentSortType={currentSortType}
            onChangeCurrentSortType={setCurrentSortType}
            sortHandler={sortHandler}
            toggleItems={['100', '300', '500']}
            toggleValues={takeParamRequest}
            onChaneToggleButton={onChaneTakeRequest}
            onExportExcel={onExportExcel}
            disabledExportExcelBtn={isExcelLoading}
            maxHeight={'calc(100% - 55px)'}
            showScrollButton
            hideBorder
            onChangePage={changePage}
            totalCount={channelTableStore.totalCount}
            paginateStep={Array.isArray(takeParamRequest) ? +takeParamRequest[0] : takeParamRequest}
            page={channelTableStore.page}
            showCheckboxCell
            showBorderRadius={false}
            showBoxShadow
          >
            <ChannelTableList tableHead={tableHead}/>
          </TableDefault>
          {isSettingsOpen &&
            <IntegrationTableSettings
              open={isSettingsOpen}
              onClose={()=>setSettingsOpen(false)} 
              onSubmit={submitSettings} 
              onReset={resetSettings}
              deleteConfirmItem={deleteConfirmItem} 
              loading={isLoadingSettings}
              arrayConfirmItems={channelTableStore.confirmLists}
              arraySelectItems={channelTableStore.selectLists}
              onDragStart={channelTableStore.onDragStart}
              onDragOver={channelTableStore.onDragOver}
              onDrop={channelTableStore.onDrop}
              addToConfirmList={addConfirmList}
              removeConfirmList={removeConfirmArr}
              setSelectedChecked={setSelectChecked}
              currentArray={channelTableStore.confirmLists}
            />
          }
				 <Routes>
           {channelMainSectionRoutes.map(({path, element: Element}) => <Route key={path} path={path} element={<Element/>}/>)}
				 </Routes>
          <NotificationAlerts
            sucsess={{text:'Настройки успешно сохранились!', open:showAlert, onClose:()=>setShowAlert(false)}}
            error={{text:errorAlert ? errorAlert : '', open:errorAlert ? true : false, onClose:()=>setErrorAlert(null)}}
          />
        </>
      }
    </>
  )
})