import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, memo } from "react";
import { purple_color } from "../../../../../../app/styles/variables";
import { EmptyText } from "../../../../../../components/EmptyText/EmptyText";
import { CopyIconButton } from "../../../../../../components/UI/Buttons/CopyIconButton/CopyIconButton";
import { ContractInput } from "../../../ContractBlock/ui/ContractInput/ContractInput";
import { IOrdCard } from "./IOrdCard";
import { OrdSelect } from "./ui/OrdSelect/OrdSelect";
import { OrdSelectAdvertisement } from "./ui/OrdSelectAdvertisement/OrdSelectAdvertisement";
import { OrdSelectEridText } from "./ui/OrdSelectEridText/OrdSelectEridText";
import userStore from "../../../../../../store/user.store";
import { ALL_ROLES } from "../../../../../../shared/rules/interface/EnumRights";

export const OrdCard: FC<IOrdCard> = memo((props) => {
  const {
    ordInfo,
    isEditMode,
    isLoading,
    changeEditCheckbox,
    changeSimpleEditInput,
    changeSelect,
    showDivider = true,
  } = props;

  const disabled = ordInfo.isSelf ? false : true;
  const readOnly = !(
    userStore.user?.role === ALL_ROLES.FINANCE_MANAGER ||
    userStore.user?.role === ALL_ROLES.ADMIN ||
    userStore.user?.role === ALL_ROLES.PRE_HEAD_MANAGER ||
    userStore.user?.role === ALL_ROLES.RELEASE_MANAGER
  );

  return (
    <Box>
      <Stack spacing={"6px"} sx={{ mt: "6px" }}>
        <Typography fontWeight={"bold"} sx={{ mb: -1 }}>
          Интеграция {ordInfo?.integration?.id} Промокод {ordInfo?.integration?.promokod?.code}
        </Typography>
        <Box
          sx={{
            display: "flex",
            mb: -1,
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "row" },
          }}>
          <FormGroup sx={{ width: "100%" }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              row
              sx={{ flexWrap: "nowrap" }}
              value={ordInfo?.isSelf}
              onChange={(e) => changeEditCheckbox(ordInfo?.id, "isSelf", e)}>
              <FormControlLabel
                value={false}
                control={<Radio />}
                sx={{ width: "50%" }}
                disabled={!isEditMode || readOnly}
                label={
                  <Typography sx={{ "&:hover": { color: purple_color } }}>
                    НЕ мы оформляем ОРД
                  </Typography>
                }
              />
              <FormControlLabel
                value={true}
                control={<Radio />}
                sx={{ width: "50%" }}
                disabled={!isEditMode || readOnly}
                label={
                  <Typography sx={{ "&:hover": { color: purple_color } }}>
                    МЫ оформляем ОРД
                  </Typography>
                }
              />
            </RadioGroup>
          </FormGroup>
          <Box sx={{ display: "flex" }}></Box>
        </Box>
        <Stack spacing={"6px"}>
          {!isEditMode && (
            <Stack direction={"row"} alignItems={"center"}>
              <Typography sx={{ width: "50%" }}>Текст erid:</Typography>
              <Typography sx={{ width: "50%", lineBreak: "anywhere" }}>
                {ordInfo?.eridText?.text || <EmptyText />}
              </Typography>
            </Stack>
          )}
          {isEditMode && (
            <OrdSelectEridText
              text={ordInfo.eridText ? ordInfo?.eridText?.id?.toString() : ""}
              field="eridText"
              onChange={changeSimpleEditInput}
              id={ordInfo?.id || undefined}
              title={"Текст erid:"}
              ordInfo={ordInfo}
              disabled={disabled}
            />
          )}
        </Stack>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "50%", display: "flex", alignItems: "center" }}>
            <Typography>Токен ОРД erid:</Typography>
          </Box>
          <Box sx={{ width: "50%", display: "flex", alignItems: "center" }}>
            {isEditMode && (
              <ContractInput
                field="token"
                value={(disabled ? "" : ordInfo?.token) || ""}
                onChange={changeSimpleEditInput}
                placeholder="Введите значение"
                disabled={isLoading || disabled}
                fullWidth
                id={ordInfo?.id}
              />
            )}
            {!isEditMode && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ width: "100%" }}>
                <Typography noWrap>{ordInfo?.token || <EmptyText />}</Typography>
                {ordInfo?.token && (
                  <Tooltip
                    title={<Typography>Копирование Текста erid + Токен ОРД erid</Typography>}
                    placement="top">
                    <CopyIconButton
                      copyText={
                        ordInfo?.eridText?.text
                          ? `${ordInfo?.eridText.text} ${ordInfo?.token}`
                          : `${ordInfo?.token}`
                      }
                    />
                  </Tooltip>
                )}
              </Stack>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "50%", display: "flex", alignItems: "center" }}>
            <Typography>Ссылка на креатив:</Typography>
          </Box>
          <Box sx={{ width: "50%", display: "flex", alignItems: "center" }}>
            {isEditMode && (
              <ContractInput
                field="linkToCreative"
                value={(disabled ? "" : ordInfo?.linkToCreative) || ""}
                onChange={changeSimpleEditInput}
                placeholder="Введите значение"
                disabled={isLoading || disabled}
                fullWidth
                id={ordInfo?.id}
              />
            )}
            {!isEditMode && (
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ width: "100%" }}>
                <>
                  {ordInfo?.linkToCreative ? (
                    <Link href={ordInfo?.linkToCreative} target="_blank" color={"secondary"} noWrap>
                      {ordInfo?.linkToCreative}
                    </Link>
                  ) : (
                    <Typography>{ordInfo?.linkToCreative || <EmptyText />}</Typography>
                  )}
                </>
                {ordInfo?.linkToCreative && (
                  <CopyIconButton copyText={`${ordInfo?.linkToCreative}`} />
                )}
              </Stack>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "50%", display: "flex", alignItems: "center" }}>
            <Typography>Формат распространения рекламы:</Typography>
          </Box>
          <Box sx={{ width: "50%", display: "flex", alignItems: "center" }}>
            {isEditMode && (
              <OrdSelect
                value={ordInfo?.format?.code || ""}
                onChange={changeSelect}
                id={ordInfo?.id}
                disabled={isLoading || disabled}
              />
            )}
            {!isEditMode && (
              <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                <Typography>{ordInfo?.format?.name || <EmptyText />}</Typography>
                {ordInfo?.format?.name && (
                  <CopyIconButton sx={{ ml: "auto" }} copyText={`${ordInfo?.format?.name}`} />
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Stack spacing={1}>
          {!isEditMode && (
            <Stack direction={"row"} alignItems={"center"}>
              <Typography sx={{ width: "50%" }}>Рекламный текст:</Typography>
              <Box sx={{ display: "flex", alignItems: "center", width: "50%" }}>
                <Typography>{ordInfo?.text?.text || <EmptyText />}</Typography>
                <CopyIconButton copyText={`${ordInfo?.text?.text}`} />
              </Box>
            </Stack>
          )}
          {isEditMode && (
            <OrdSelectAdvertisement
              text={ordInfo.text ? ordInfo?.text?.id?.toString() : ""}
              field="text"
              onChange={changeSimpleEditInput}
              id={ordInfo?.id || undefined}
              ordInfo={ordInfo}
              readOnly={isLoading || disabled}
              title={"Рекламный текст:"}
              disabled={disabled}
            />
          )}
          <Box display={"flex"}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isEditMode ? (
                <Checkbox
                  checked={Boolean(ordInfo?.hasAct)}
                  disabled={isLoading || disabled}
                  onChange={(e) => changeEditCheckbox(ordInfo?.id, "hasAct", e)}
                />
              ) : (
                <Checkbox checked={Boolean(ordInfo?.hasAct)} onChange={() => ""} disabled />
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography whiteSpace={"nowrap"}>Акт внесён в ОРД</Typography>
            </Box>
          </Box>
        </Stack>
      </Stack>
      {showDivider && <Divider sx={{ mt: 1.5 }} />}
    </Box>
  );
});
