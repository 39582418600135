import { AxiosError } from "axios";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import AgreementsService from "../../../../../services/agreements/agreements.service";
import { IAgreementsRequestupdateStatus } from "../../../../../services/agreements/IAgreementsRequest";
import statusesStore from "../../../../../store/statuses.store";
import { StatusIconList } from "../../../../StatusIconList/StatusIconList";
import { IAgreementStatuses } from "./IAgreementStatuses";

export const AgreementStatuses: FC<IAgreementStatuses> = observer((props) => {
  const { statusCode, agreementId, userId, readonly = false, agreementStatusCallback } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [status, setStatus] = useState(statusCode);
  const [isLoading, setLoading] = useState(false);

  let bgColor = "#inherit";

  if (statusCode === "agreed") {
    bgColor = "rgb(128, 220, 159)";
  } else if (statusCode === "not_agreed") {
    bgColor = "rgb(239, 83, 80, 0.6)";
  } else if (statusCode === "revision") {
    bgColor = "#68AEFF";
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeStatus = (status: string) => {
    setLoading(true);

    const option: IAgreementsRequestupdateStatus = {
      agreementId: agreementId,
      newStatus: status,
      userId: userId,
    };
    AgreementsService.updateStatus(option)
      .then((res) => {
        // console.log(res, 'update status agree')

        if (agreementStatusCallback) {
          agreementStatusCallback(res.data);
        }

        setStatus(status);
        setAnchorEl(null);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err status agree')
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setStatus(statusCode);
  }, [statusCode]);

  return (
    <>
      {statusesStore.agreement && (
        <StatusIconList
          anchorEl={anchorEl}
          onCloseList={handleClose}
          onChangeStatus={onChangeStatus}
          onClickList={handleClick}
          list={statusesStore.agreement}
          status={status}
          disabled={isLoading}
          readOnly={readonly}
          btnSx={{
            whiteSpace: "nowrap",
            backgroundColor: bgColor,
            "&:hover": { backgroundColor: bgColor },
          }}
        />
      )}
    </>
  );
});
