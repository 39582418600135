import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, Fragment } from "react";
import { filterSelectionStrategy } from "../../../../strategy/Filters/filterStrategy";
import { IStrategyFiltersField } from "../../../../strategy/Filters/interface/IStrategyFiltersField";
import { FilterSeacrhAgency } from "../../../ChannelTable/ui/FilterSeacrhAgency/FilterSeacrhAgency";
import { SelectFilterField } from "../../../ContrActorTable/ui/FilterField/SelectFilterField";
import { FinanceSelect } from "../../../FinancecTable/ui/FinanceFilter/FinanceSelect";
import { FilterCheckbox } from "../../UI/FilterCheckbox/FilterCheckbox";
import { FilterDateMultiple } from "../../UI/FilterDateMultiple/FilterDateMultiple";
import { FilterMultiple } from "../../UI/FilterMultiple/FilterMultiple";
import { FilterMultipleAuto } from "../../UI/FilterMultipleAuto/FilterMultipleAuto";
import { FilterSimpleField } from "../../UI/FilterSimpleField/FilterSimpleField";
import { FilterSeacrhChannel } from "../FilterSeacrhChannel/FilterSeacrhChannel";
import { FilterSearchPaymentDirection } from "../FilterSearchPaymentDirection/FilterSearchPaymentDirection";
import { IRenderFiltersList } from "./IRenderFiltersList";
import { RenderReferenceFilter } from "./UI/RenderReferenceFilter/RenderReferenceFilter";

export const RenderFiltersList: FC<IRenderFiltersList> = observer((props) => {
  const { filters, dataMinMax, minMaxLoading } = props;

  const strategyFilters: IStrategyFiltersField[] = filters.map((filter) => {
    return filterSelectionStrategy(filter);
  });

  return (
    <>
      {strategyFilters.map((filter) => {
        return (
          <Fragment key={filter.code}>
            {filter.isWrittingChips && (
              <FilterMultipleAuto
                label={filter.label}
                placeholder={filter.placeholder || ""}
                searchParamName={filter.searchUrl}
                inputType={filter.inputType || "text"}
              />
            )}
            {filter.isSearchChip && (
              <FilterSeacrhChannel searchParamsName={filter.searchUrl} limit={3} />
            )}
            {filter.isAgensyChips && <FilterSeacrhAgency />}
            {filter.isSearchDirection && (
              <FilterSearchPaymentDirection searchParamsName={filter.searchUrl} limit={3} />
            )}
            {filter.isReferenceChips && filter.referenceStoreContentFn && (
              <RenderReferenceFilter
                content={[]}
                label={filter.label}
                placeholder={filter.placeholder || ""}
                referenceContentFn={filter.referenceStoreContentFn}
                referenceStore={filter.referenceStore}
                searchParamName={filter.searchUrl}
                limitTags={filter?.limit}
                showOptionLabelValue={filter.referenceShowOptionLabelValue}
                isGoodsSelect={filter.isGoodsSelect}
              />
            )}
            {filter.isTexts && (
              <FilterSimpleField
                label={filter.label}
                placeholder={filter.placeholder}
                urlParam={filter.searchUrl}
                type={filter.inputType}
              />
            )}
            {filter.isFinanceSelects && filter.contentFn && !filter.isSelectFilterField && (
              <FinanceSelect
                label={filter.label}
                searchParamName={filter.searchUrl}
                content={filter.contentFn()}
                placeholder={filter.placeholder || ""}
              />
            )}
            {filter.isSelectFilterField && (
              <SelectFilterField
                label={filter.label}
                field={filter.isSelectFilterFieldType || ""}
                placeholder={filter.placeholder || ""}
                searchParamName={filter.searchUrl}
              />
            )}
            {filter.isMultiples && (
              <FilterMultiple
                initValue={dataMinMax?.[filter.code] || filter.dataMinMax}
                label={filter.label}
                searchParamName={filter.searchUrl}
                isLoading={minMaxLoading}
                hiddenSlider
                showLabel
                step={filter.step}
              />
            )}
            {filter.isDates && (
              <FilterDateMultiple label={filter.label} searchParamName={filter.searchUrl} />
            )}
            {filter.isCheckboxs && (
              <Box sx={{ display: "flex", alignItems: "end" }}>
                <FilterCheckbox label={filter.label} searchParamName={filter.searchUrl} />
              </Box>
            )}
          </Fragment>
        );
      })}
    </>
  );
});
