import { VerifiedUser } from "@mui/icons-material";
import { Icon, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { IVerifiedIcon } from "./IVerifiedIcon";

export const VerifiedIcon: FC<IVerifiedIcon> = (props) => {
  const {
    isVerified = true,
    sx,
    showTooltip = true,
    iconSize = "medium",
    bgSize = "large",
    audience,
  } = props;

  const checkAudience = audience <= 9999;

  return (
    <Icon
      sx={{
        ...(isVerified && { backgroundColor: "#78e379" }),
        ...(checkAudience && { opacity: ".5" }),
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
      fontSize={bgSize}>
      <Tooltip title={showTooltip && <Typography>Зарегистрирован на Госуслугах</Typography>}>
        <VerifiedUser
          color={isVerified ? "primary" : "disabled"}
          sx={{ ...(bgSize === "large" && { mr: ".5px" }) }}
          fontSize={iconSize}
        />
      </Tooltip>
    </Icon>
  );
};
