import { makeAutoObservable, toJS } from "mobx";
import { IDragParams, setCheckedParams } from "./integrationTable.store";
import { ISettingsSelected } from "../components/IntegrationTable/models/ISettingsSelected";
import { ISettingsSelectedGroup } from "../components/IntegrationTable/models/ISettingsSelectedGroup";
import { IStatusItem } from "../components/Statuses/models/IStatusItem";
import { createTableData, transformPaymentItem } from "../components/FinancecTable/shared/dataList";
import {
  IPaymentsFilteredStatsResponse,
  IPaymentsFullResponse,
} from "../services/payments/IPaymentsResponse";
import { IRenderFilterLists } from "../components/Filters/interfaces/IRenderFilterItem";
import { LS_FILTERS_ALL_FINANCE, LS_FILTERS_FINANCE } from "../shared/constants/localstorage";

import allFilterListJson from "../shared/mock/filters.mock.json";
import { IUpdateStatusesRequest } from "../services/integration/IntegrationsRequest";

class PaymentsTableStore {
  page: number = 1;

  tableItems: any[] = [];

  allFilter: string[] = [];

  isLoading: boolean = true;

  isLoadingSort: boolean = true;

  totalCount: number = 0;

  isPageNotFound: boolean = false;

  initSettingsConfirm: ISettingsSelected[] = [];
  initSettingsSelect: ISettingsSelectedGroup[] = [];

  selectLists: ISettingsSelectedGroup[] = [];
  confirmLists: ISettingsSelected[] = [];

  curDragItemParams: IDragParams | null = null;

  stats: null | IPaymentsFilteredStatsResponse = null;

  paymentslFilters: IRenderFilterLists[] = localStorage.getItem(LS_FILTERS_FINANCE)
    ? JSON.parse(localStorage.getItem(LS_FILTERS_FINANCE)!)
    : allFilterListJson.paymentsAllFilters;

  paymentsAllFilters: IRenderFilterLists[] = localStorage.getItem(LS_FILTERS_ALL_FINANCE)
    ? JSON.parse(localStorage.getItem(LS_FILTERS_ALL_FINANCE)!)
    : allFilterListJson.paymentsAllFilters;

  paymentPresetFilters: IRenderFilterLists[] = [];
  paymentAllPresetFilters: IRenderFilterLists[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setPaymentPresetAllFilters = (paymentAllPresetFilters: IRenderFilterLists[]) => {
    this.paymentAllPresetFilters = paymentAllPresetFilters;
  };

  setPaymentPresetFilters = (paymentPresetFilters: IRenderFilterLists[]) => {
    this.paymentPresetFilters = paymentPresetFilters;
  };

  setPaymentsFilters = (paymentslFilters: IRenderFilterLists[]) => {
    this.paymentslFilters = paymentslFilters;
  };

  setPaymentsAllFilters = (paymentsAllFilters: IRenderFilterLists[]) => {
    this.paymentsAllFilters = paymentsAllFilters;
  };

  setIsPageNotFound(value: boolean) {
    this.isPageNotFound = value;
  }

  setLoading(value: boolean) {
    this.isLoading = value;
  }

  setStats(stats: IPaymentsFilteredStatsResponse | null) {
    this.stats = stats;
  }

  setTotalCount(count: number) {
    this.totalCount = count;
  }

  setPage(page: number) {
    this.page = page;
  }

  setAllFilter(array: string[]) {
    this.allFilter = array;
  }

  setTableItems(items: any[]) {
    this.tableItems = items;
  }
  addTableItem(item: any) {
    createTableData(this.tableItems, item, true);
  }

  setSelectedList(array: ISettingsSelectedGroup[]) {
    this.selectLists = array;
  }

  setConfirmList(array: ISettingsSelected[]) {
    this.confirmLists = array;
  }

  setInitSettingsSelect(array: ISettingsSelectedGroup[]) {
    this.initSettingsSelect = array;
  }

  setInitSettingsConfirm(array: ISettingsSelected[]) {
    this.initSettingsConfirm = array;
  }

  changePayment = (id: number, item: any) => {
    this.tableItems = this.tableItems.map((payment) => {
      if (payment.id === id) {
        return transformPaymentItem(item);
      } else return payment;
    });
  };

  changePaymentInTable = (item: IPaymentsFullResponse) => {
    this.tableItems = this.tableItems.map((payment) => {
      if (payment.id === item.id) {
        return transformPaymentItem(item);
      } else return payment;
    });
  };

  updateCompanyAccount = (id: string, code: string, name: string) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newtItem = {
          ...item,
          companyAccount: {
            code,
            name,
          },
        };
        return newtItem;
      } else return item;
    });
  };

  changeCommission = (id: string, price: string) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          commission: +price,
          totalSumm: +price + item.amount,
        };
        return newItem;
      } else return item;
    });
  };

  changeField = (option: IUpdateStatusesRequest) => {
    const { field, id, new_value, update_id } = option;
    if (this.tableItems) {
      this.tableItems = this.tableItems.map((item) => {
        const updatedIntegrations = item.integrations.map((el: any) => {
          if (el.id === id) {
            return {
              ...el,
              update_id,
              [field]: new_value,
            };
          }
          return el;
        });
        return {
          ...item,
          integrations: updatedIntegrations,
        };
      });
    }
  };

  changeStatusTable = (id: string, type: string) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          status: { code: type },
          paidSumm: type === "paid" ? item.totalSumm : item.paidSumm,
        };
        return newItem;
      }
      return item;
    });
  };

  changeUpdateIdItem = (id: number, update_id: number, type: IStatusItem["type"]) => {
    this.tableItems = this.tableItems.map((item) => {
      if (item.id === id) {
        const newItem = {
          ...item,
          update_id,
          status: { code: type },
        };
        return newItem;
      }
      return item;
    });
  };

  onConfirmDelete(id: string) {
    this.selectLists.map((groupArr) =>
      groupArr.fields.map((item) =>
        item.code === id ? (item.isSelected = !item.isSelected) : item
      )
    );
  }

  addToConfirmList(item: ISettingsSelected) {
    const newArr: any[] = [
      ...this.confirmLists,
      { ...item, ordering: this.confirmLists.length + 1 },
    ];

    this.confirmLists = newArr;
  }

  setSelectedChecked(params: setCheckedParams) {
    const { code, group } = params;

    this.selectLists.map((groupArr) =>
      groupArr.group === group
        ? groupArr.fields.map((item) =>
            item.code === code ? (item.isSelected = !item.isSelected) : item
          )
        : groupArr
    );
  }

  removeConfirmList(id: string) {
    const newArr: any[] = [
      ...this.confirmLists
        .filter((item) => item.code !== id)
        .map((item, i) => {
          return { ...item, ordering: i + 1 };
        }),
    ];

    this.confirmLists = newArr;
  }

  onDragStart = (params: IDragParams) => {
    this.curDragItemParams = params;
  };

  onDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  onDrop = (e: React.DragEvent, params: IDragParams) => {
    e.preventDefault();

    if (
      params.code &&
      params.ordering &&
      this.curDragItemParams?.code &&
      this.curDragItemParams?.ordering &&
      params.code !== this.curDragItemParams.code
    ) {
      const dragItem = { code: this.curDragItemParams.code, ordering: params.ordering };
      const downItem = {
        params,
        code: params.code,
        ordering:
          this.curDragItemParams.ordering < params.ordering
            ? params.ordering - 1
            : params.ordering + 1,
      };

      const afterDragArr: any[] = this.confirmLists.map((item) => {
        if (item.code === dragItem.code) {
          return { ...item, ...dragItem };
        } else if (item.code === downItem.code) {
          return { ...item, ...downItem };
        } else {
          return {
            ...item,
            ordering: item.ordering >= dragItem.ordering ? item.ordering + 1 : item.ordering - 1,
          };
        }
      });

      this.confirmLists = afterDragArr
        .sort((a, b) => (a.ordering > b.ordering ? 1 : -1))
        .map((x, i) => {
          return { ...x, ordering: i + 1 };
        });
    }
  };

  sortTableItem(type: string, sortType: "asc" | "desc") {
    this.isLoadingSort = true;

    setTimeout(() => {
      const sortedItems = this.tableItems.map((item: any) => {
        if (Array.isArray(item[type])) {
          // Сортировка вложенного массива
          item[type].sort((a: any, b: any) => {
            if (typeof a === "number" && typeof b === "number") {
              return sortType === "asc" ? a - b : b - a;
            } else if (typeof a === "string" && typeof b === "string") {
              return sortType === "asc" ? a.localeCompare(b) : b.localeCompare(a);
            } else {
              return 0;
            }
          });
        }
        return item;
      });


      this.tableItems = sortedItems.sort((a: any, b: any) => {
        const aValue = Array.isArray(a[type]) ? a[type][0] : a[type];
        const bValue = Array.isArray(b[type]) ? b[type][0] : b[type];

        if (aValue === null || aValue === undefined) {
          return 1;
        }

        if (bValue === null || bValue === undefined) {
          return -1;
        }

        if (typeof aValue === "object" && typeof bValue === "object" && aValue.number && bValue.number) {
          const aNumber = Number(aValue.number);
          const bNumber = Number(bValue.number);
  
          return sortType === "asc" ? aNumber - bNumber : bNumber - aNumber;
        }

        if (typeof aValue === "number" && typeof bValue === "number") {
          // Сортировка чисел
          return sortType === "asc" ? aValue - bValue : bValue - aValue;
        }

        if (typeof aValue === "string" && typeof bValue === "string") {
          // Сортировка строк
          return sortType === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        if (aValue instanceof Date && bValue instanceof Date) {
          // Сортировка дат
          return sortType === "asc"
            ? aValue.getTime() - bValue.getTime()
            : bValue.getTime() - aValue.getTime();
        }

        return 0;
      });

      setTimeout(() => {
        this.isLoadingSort = false;
      }, 0);
    }, 0);
  }
}

export default new PaymentsTableStore();
