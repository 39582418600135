import { makeAutoObservable } from "mobx";
import {
  ISettingsFilters,
  IStatusesAgreements,
  IStatusesChannel,
  IStatusesPayments,
} from "../components/Statuses/models/ISettingsFilters";

class StatusesStore {
  statuses: ISettingsFilters = {} as ISettingsFilters;

  channel: IStatusesChannel = {} as IStatusesChannel;

  payments: IStatusesPayments | null = null;

  agreement: IStatusesAgreements | null = null;

  pay: IStatusesPayments | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setStatuses(statuses: ISettingsFilters) {
    this.statuses = statuses;
  }

  setChannel(channel: IStatusesChannel) {
    this.channel = channel;
  }

  setStatusPayments(payments: IStatusesPayments) {
    this.payments = payments;
  }

  setStatusAgreement(agreement: IStatusesAgreements) {
    this.agreement = agreement;
  }

  setStatusPay(pay: IStatusesPayments) {
    this.pay = pay;
  }
}

export default new StatusesStore();
