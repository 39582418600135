import { FC } from "react";
import { IAgrementIcons } from "./IAgrementIcons";
import { Box, Stack } from "@mui/material";

export const AgrementIcons: FC<IAgrementIcons> = (props) => {
  const { userApprovments, id } = props;

  return (
    <Stack spacing={0.3}>
      {userApprovments.map((approve, ind) => {
        if (approve.approvedStatus?.code === "wait") {
          return (
            <Box
              key={approve.approvedStatus?.code + id + ind}
              sx={{ borderRadius: "100%", height: 6, width: 6, backgroundColor: "grey" }}
            />
          );
        } else if (approve.approvedStatus?.code === "agreed") {
          return (
            <Box
              key={approve.approvedStatus?.code + id + ind}
              sx={{ borderRadius: "100%", height: 6, width: 6, backgroundColor: "green" }}
            />
          );
        } else if (approve.approvedStatus?.code === "revision") {
          return (
            <Box
              key={approve.approvedStatus?.code + id + ind}
              sx={{ borderRadius: "100%", height: 6, width: 6, backgroundColor: "#278AFF" }}
            />
          );
        } else {
          return (
            <Box
              key={approve.approvedStatus?.code + id + ind}
              sx={{ borderRadius: "100%", height: 6, width: 6, backgroundColor: "red" }}
            />
          );
        }
      })}
    </Stack>
  );
};
