import { FC, useState } from "react";
import { ChannelNamePopUp } from "../ChannelNamePopUp/ChannelNamePopUp";
import ChannelService from "../../../../../services/channel/channel.service";
import { IUpdateStatusesRequest } from "../../../../../services/integration/IntegrationsRequest";
import { AxiosError } from "axios";
import integrationTableStore from "../../../../../store/integrationTable.store";
import { IIntegrationPopUp } from "./IIntegrationPopUp";
import { observer } from "mobx-react-lite";

export const IntegrationPopUp: FC<IIntegrationPopUp> = observer((props) => {
  const {
    channelType,
    link,
    name,
    channelId,
    showChannelButton = false,
    update_id,
    channelName,
    readOnly = false,
    isBanned,
    showCopy,
    showIcon,
    width,
    showVideoReviewIcon,
    showPaymentOnCardIcon,
    showVerifiedIcon,
    audience
  } = props;

  const [isOpenEdit, setOpenEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const submitEditLink = (linkValue: string) => {
    setLoading(true);
    const option: IUpdateStatusesRequest = {
      field: "link",
      id: channelId,
      new_value: linkValue,
      update_id,
    };
    ChannelService.updateSimpleField(option)
      .then((res) => {
        // console.log(res, 'res upodate link')

        integrationTableStore.updateLink(channelName, res.data.update_id, linkValue);
        setOpenEdit(false);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          // console.log(err, 'err link edit')
          setLoading(false);
        }
      });
  };

  return (
    <ChannelNamePopUp
      showPaymentOnCardIcon={showPaymentOnCardIcon}
      channelId={channelId}
      name={name}
      link={link}
      channelType={channelType}
      openEditModal={isOpenEdit}
      onCloseEditModal={() => setOpenEdit(false)}
      onOpenEditModal={() => setOpenEdit(true)}
      onLoadingModalEdit={isLoading}
      onSubmitEdit={submitEditLink}
      showChannelButton={showChannelButton}
      readOnly={readOnly}
      isBanned={isBanned}
      showCopy={showCopy}
      showIcon={showIcon}
      width={width}
      showVideoReviewIcon={showVideoReviewIcon}
      showVerifiedIcon={showVerifiedIcon}
      audience={audience}
    />
  );
});
